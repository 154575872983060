.study-abroad-container {
    width: 85%;
    margin: auto;
    /* padding: 120px; */
    padding-top: 70px;
    /* margin-top: 15vh; */
    /* font-family: Arial, sans-serif; */
  }
  
  .title {
    text-align: center;
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .countries-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .country-card {
    width: 48%;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .country-card:hover {
    transform: scale(1.02);
  }
  
  .country-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .country-info {
    padding: 15px;
  }
  
  .flag {
    width: 40px;
    height: 25px;
    margin-bottom: 10px;
  }
  
  .study-abroad-container h3 {
    color: #222;
    font-size: 22px;
    margin: 10px 0;
  }
  
  .study-abroad-container p {
    color: #555;
    font-size: 16px;
  }
  
  .study-abroad-container h4 {
    margin-top: 10px;
    color: #444;
  }
  
  .study-abroad-container ul {
    padding-left: 20px;
  }
  
  .study-abroad-container ul li {
    font-size: 14px;
    color: #666;
  }
  
  .admission {
    display: flex;
    justify-content: space-between;
  }
  
  .admission div {
    width: 48%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .countries-container {
      flex-direction: column;
    }
  
    .country-card {
      width: 100%;
    }
  
    .admission {
      flex-direction: column;
    }
  
    .admission div {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  